<script>
import {
    ArrowUpIcon
} from 'vue-feather-icons';
import {
    Carousel,
    Slide
} from 'vue-carousel';

import Navbar from "@/components/navbar0";

import Footer from "@/components/footer";
import store from "@/store/store";

/**
 * Shop-cart component
 */
export default {
    data() {
        return {

          hover:false,

            counter: 0,

            cart_price: {
              subtotal: 0,
              discount: 0,
              total: 0,
            },

            resumes: [],

          in_sql: {
              user_id:0,
              resume_id:0,
              cart_id:0,
          },

          add_cash_sql_in: {
            user_email: '',
            user_id: 0,
            service_name: 'Покупка резюме Skillrank',
            description: 'Покупка резюме Skillrank',
            price: 0,
            price_rub: 0,
            quantity: 1,
          },

        }
    },
    components: {
        Navbar,

        Footer,
        Carousel,
        Slide,
        ArrowUpIcon
    },

    mounted() {
      this.getResumesInCart();
    },

    methods: {
        increase(val) {
            this.list.map(product => {
                if (product.id === val) {
                    product.qty += 1;
                }
            });
        },
        decrement(val) {
            this.list.map(product => {
                if (product.id === val) {
                    while (product.qty > 0) {
                        product.qty -= 1;
                    }
                }

            });
        },

      goToResume(x){
        let route = this.$router.resolve({path: '/resume/'+x});
        window.open(route.href, '_blank');
      },

      getResumesInCart() {


        this.in_sql.user_id = store.state.user_id;

        this.axios({
          method: 'post',
          url: '' + this.$hostname + '/resume-cart/get-resumes-paid?access_token=' + store.state.token,
          data: this.in_sql,
        }).then((res) => {
          this.resumes = res.data.resumes
          this.cart_price.subtotal = res.data.subtotal;
          this.cart_price.total = this.cart_price.subtotal-this.cart_price.discount;
        });


      },



    }
}
</script>

<template>
<div>
    <Navbar />

    <section class="section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="table-responsive bg-white shadow">
                        <table class="table table-center table-padding mb-0">
                            <thead>
                                <tr>

                                    <th class="py-3" style="min-width: 100px;">Имя</th>
                                    <th class="py-3" style="min-width: 300px;">Должность</th>
                                    <th class="text-center py-3" style="min-width: 160px;">Резюме</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(resume, index) of resumes" :key="index">
                                  <td @mouseover="hover = true"  @mouseleave="hover = false" @click="goToResume(resume.resume_id)" :class="{ active: hover }">
                                    <a   class="text-primary ml-3"
                                    >{{resume.full_name}}<i class="uil uil-arrow-right"></i></a>
                                  </td>
                                  <td>
                                    <div class="d-flex align-items-center">
                                      <h6 class="mb-0 ml-3">{{resume.resume_headline}}</h6>
                                    </div>
                                  </td>
                                    <td class="text-center">{{resume.email}}</td>
                                  <!--
                                    <td class="text-center">
                                        <input type="button" value="-" class="minus btn btn-icon btn-soft-primary font-weight-bold" @click="decrement(item.id)">
                                        <input type="text" v-model="item.qty" step="1" min="1" name="quantity" value="2" title="Qty" class="btn btn-icon btn-soft-primary font-weight-bold ml-1">
                                        <input type="button" value="+" class="plus btn btn-icon btn-soft-primary font-weight-bold ml-1" @click="increase(item.id)">
                                    </td>
                                    <td class="text-center font-weight-bold">${{item.total}}.00</td>
                                    -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

        </div>

    </section>
    <Footer />
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>

</div>
</template>


<style lang="scss">


.active
{
  cursor: pointer;
}

</style>